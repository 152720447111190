import moment from "moment";

export const saveJson = (scheduleDef, resourceList, isDraft) => {
  return {
    id: 0,
    organization_id: scheduleDef?.facility_name?._id,
    resourcetype: scheduleDef?.resource_type?.value,
    resourcerole: scheduleDef?.resource_role?.value,
    resourcecode: scheduleDef?.resources?.map((val) => val?.value),
    effectivefrom: Math.floor(new Date(scheduleDef?.start_date) / 1000),
    effectiveto: Math.floor(new Date(scheduleDef?.end_date) / 1000),
    schedulemode: scheduleDef?.schedule_generation_mode?.value,
    scheduleappmang: scheduleDef?.schedule_available_days,
    remarks: "",
    timetable_title: scheduleDef?.scheduletitle,
    applicable_app_type: scheduleDef?.applicable_app_type?.map(
      (val) => val?.value
    ),
    entitytype: scheduleDef?.entity_type,
    status: false,
    isoverride: false,
    overridefromid: "",
    appointmentperiod: resourceList?.map((val) => {
      return {
        holidays: val?.holidaysList,
        day1: val?.Day1 ?? false,
        day2: val?.Day2 ?? false,
        day3: val?.Day3 ?? false,
        day4: val?.Day4 ?? false,
        day5: val?.Day5 ?? false,
        day6: val?.Day6 ?? false,
        day7: val?.Day7 ?? false,
        week1: val?.Week1 ?? false,
        week2: val?.Week2 ?? false,
        week3: val?.Week3 ?? false,
        week4: val?.Week4 ?? false,
        week5: val?.Week5 ?? false,
        week6: val?.Week6 ?? false,
        month1: val?.Month1 ?? false,
        month2: val?.Month2 ?? false,
        month3: val?.Month3 ?? false,
        month4: val?.Month4 ?? false,
        month5: val?.Month5 ?? false,
        month6: val?.Month6 ?? false,
        month7: val?.Month7 ?? false,
        month8: val?.Month8 ?? false,
        month9: val?.Month9 ?? false,
        month10: val?.Month10 ?? false,
        month11: val?.Month11 ?? false,
        month12: val?.Month12 ?? false,
        appointmentdurationid: val?.appointmentList?.map((value) => {
          return {
            slotslabind: value?.duration_type?.value,
            daytype: value?.day_type?.value,
            starttime: value?.start_time.replace(" ", ""),
            endtime: value?.end_time.replace(" ", ""),
            slotduration: parseInt(value?.schedule_duration),
            maxappointment: parseInt(value?.max_appoinment),
            maxappallowed: parseInt(value?.max_appoinment_allowed),
            maxwalkin: parseInt(value?.max_walking),
            maxoverbooking: parseInt(value?.max_over_booking),
            maxwaitlist: parseInt(value?.max_wait_list),
            consulttype: value?.consultation_type?.value,
          };
        }),
      };
    }),
    is_draft: isDraft,
  };
};

const getResourceData = (data, dropdown) => {
  const stateData = data?.map((value) => value?._id);
  const filteredData = dropdown?.filter((val) =>
    stateData?.includes(val?.value)
  );
  return JSON.parse(JSON.stringify(filteredData));
};

export const constructEditScheduleDef = (editData, resourceDropDown) => {
  return {
    scheduletitle: editData?.timetable_title,
    entity_type: editData?.entitytype?._id,
    facility_name: {
      value: editData?.organization_id?._id,
      label: editData?.organization_id?.name,
      ...editData?.organization_id,
    },
    resource_type: {
      value: editData?.ResourceType?._id,
      label: editData?.ResourceType?.display,
      ...editData?.ResourceType,
    },
    resource_role: {
      value: editData?.ResourceRole?._id,
      label: editData?.ResourceRole?.display,
      ...editData?.ResourceRole,
    },
    resources: getResourceData(editData?.resourcecode, resourceDropDown),
    start_date: new Date(editData?.effectivefrom * 1000),
    end_date: new Date(editData?.effectiveto * 1000),
    // slotduration: value?.schedule_duration,
    schedule_generation_mode: {
      value: editData?.schedulemode?._id,
      label: editData?.schedulemode?.display,
    },
    applicable_app_type: editData?.applicable_app_type?.map((val) => {
      return {
        value: val?._id,
        label: val?.display,
      };
    }),
  };
};

export const constructEditAvailability = (editData) => {
  const result = editData?.appointmentperiod?.map((val) => {
    return {
      Day1: val?.day1,
      Day2: val?.day2,
      Day3: val?.day3,
      Day4: val?.day4,
      Day5: val?.day5,
      Day6: val?.day6,
      Day7: val?.day7,
      Week1: val?.week1,
      Week2: val?.week2,
      Week3: val?.week3,
      Week4: val?.week4,
      Week5: val?.week5,
      Month1: val?.month1,
      Month2: val?.month2,
      Month3: val?.month3,
      Month4: val?.month4,
      Month5: val?.month5,
      Month6: val?.month6,
      Month7: val?.month7,
      Month8: val?.month8,
      Month9: val?.month9,
      Month10: val?.month10,
      Month11: val?.month11,
      Month12: val?.month12,
      isNew: false,
      holidaysList: val?.holidays?.map((val) => {
        return {
          value: "01-Jan-23",
          label: "01-Jan-23",
          disabled: true,
          name: "01-Jan-23",
        };
      }),
      appointmentList: val?.appointmentdurationid?.map((value) => {
        return {
          duration_type: {
            value: value?.slotslabind,
            label: value?.slotslabind,
          },
          day_type: {
            value: value?.daytype?._id,
            label: value?.daytype?.display,
            ...value?.daytype,
          },
          start_time: value?.starttime,
          end_time: value?.endtime,
          slotduration: value?.slotduration,
          max_appoinment: value?.maxappointment,
          max_appoinment_allowed: value?.maxappallowed,
          max_walking: value?.maxwalkin,
          max_over_booking: value?.maxoverbooking,
          max_wait_list: value?.maxwaitlist,
          consultation_type: {
            value: value?.consulttype?._id,
            label: value?.consulttype?.display,
            ...value?.consulttype,
          },
        };
      }),
    };
  });
  return result;
};

export const constructAppointmentTable = (data) => {
  const result = data?.map((val) => {
    return {
      day_type: val?.day_type?.display
        ? val?.day_type?.display
        : val?.day_type?.label,
      duration_type: val?.duration_type?.value,
      start_time: val?.start_time,
      end_time: val?.end_time,
      slot_duration: val?.slotduration ?? "-",
      max_appoinment: val?.max_appoinment,
      max_appoinment_allowed: val?.max_appoinment_allowed,
      max_over_booking: val?.max_over_booking,
      max_walking: val?.max_walking,
      max_wait_list: val?.max_wait_list,
      consultation_type: val?.consultation_type?.display
        ? val?.consultation_type?.display
        : val?.consultation_type?.label,
    };
  });
  return result;
};

export const constructEditDataPayload = (data, resourceList) => {
  const newAppointment = resourceList?.filter((val) => val?.isNew === true);
  const resultData = {
    start: moment(data?.start_date).unix(),
    end: moment(data?.end_date).unix(),
    resourcecode: data?.resources?.map((val) => val?.value),
    appointmentperiod: newAppointment?.map((val) => {
      return {
        holidays: val?.holiday ?? [],
        day1: val?.Day1 ?? false,
        day2: val?.Day2 ?? false,
        day3: val?.Day3 ?? false,
        day4: val?.Day4 ?? false,
        day5: val?.Day5 ?? false,
        day6: val?.Day6 ?? false,
        day7: val?.Day7 ?? false,
        week1: val?.Week1 ?? false,
        week2: val?.Week2 ?? false,
        week3: val?.Week3 ?? false,
        week4: val?.Week4 ?? false,
        week5: val?.Week5 ?? false,
        week6: val?.Week6 ?? false,
        month1: val?.Month1 ?? false,
        month2: val?.Month2 ?? false,
        month3: val?.Month3 ?? false,
        month4: val?.Month4 ?? false,
        month5: val?.Month5 ?? false,
        month6: val?.Month6 ?? false,
        month7: val?.Month7 ?? false,
        month8: val?.Month8 ?? false,
        month9: val?.Month9 ?? false,
        month10: val?.Month10 ?? false,
        month11: val?.Month11 ?? false,
        month12: val?.Month12 ?? false,
        appointmentdurationid: val?.appointmentList?.map((value) => {
          return {
            slotslabind: value?.duration_type?.value,
            daytype: value?.day_type?.value,
            starttime: value?.start_time,
            endtime: value?.end_time,
            slotduration: parseInt(value?.schedule_duration),
            maxappointment: parseInt(value?.max_appoinment),
            maxappallowed: parseInt(value?.max_appoinment_allowed),
            maxwalkin: parseInt(value?.max_walking),
            maxoverbooking: parseInt(value?.max_over_booking),
            maxwaitlist: parseInt(value?.max_wait_list),
            consulttype: value?.consultation_type?.value,
          };
        }),
      };
    }),
  };
  return resultData;
};

export const constructRefTypeEditDataPayload = (editData) => {
  let sepArray = [];
  for (let i = 0; i < editData?.speciality?.length; i++) {
    sepArray.push({
      label: editData?.speciality?.[i]?.label,
      _id: editData?.speciality?.[i]?._id,
    });
  }
  let diagnosisArray = [];
  for (let i = 0; i < editData?.diagnosis?.length; i++) {
    diagnosisArray.push({
      label: editData?.diagnosis?.[i]?.label,
      _id: editData?.diagnosis?.[i]?._id,
    });
  }
  let serviceArray = [];
  for (let i = 0; i < editData?.servicerequested?.length; i++) {
    serviceArray.push({
      label: editData?.servicerequested?.[i]?.label,
      _id: editData?.servicerequested?.[i]?._id,
    });
  }
  let templateArray = [];
  for (let i = 0; i < editData?.templates?.length; i++) {
    templateArray.push({
      componentname: {
        label: editData?.templates?.[i]?.componentname?.label,
        _id : editData?.templates?.[i]?.componentname?._id
      },
      sequence: parseInt(editData?.templates?.[i]?.sequence),
    });
  }
  return {
    refcode: editData?.refferalcode,
    reftype: editData?.refferaltype,
    workflow: {
      label: editData?.workflow,
    },
    spec: sepArray ?? [],
    dignosis: diagnosisArray ?? [],
    template: templateArray ?? [],
    service: serviceArray ?? [],
  };
};
export const saveRefEditType = (refEditData, reftype) => {
  let SArray = [];
  for (let i = 0; i < refEditData?.spec?.length; i++) {
    SArray.push(refEditData?.spec?.[i]?._id)
  }
  let diagnosisArray = [];
  for (let i = 0; i < refEditData?.dignosis?.length; i++) {
    diagnosisArray.push(refEditData?.dignosis?.[i]?._id);
  }
  let ServiceArray = [];
  for (let i = 0; i < refEditData?.service?.length; i++) {
    ServiceArray.push(refEditData?.service?.[i]?._id);
  }
  let templateArray = [];
  for (let i = 0; i < refEditData?.template?.length; i++) {
    templateArray.push({
      componentname: reftype?.template?.[i]?.componentname?._id,
      sequence: parseInt(reftype?.template?.[i]?.sequence),
    });
  }
  return {
    refferalcode: refEditData?.refcode,
    refferaltype: refEditData?.reftype,
    queueid: refEditData?.workflow?._id,
    specialityid: SArray ?? [],
    diagnosis: diagnosisArray ?? [],
    templates: templateArray ?? [],
    servicerequested: ServiceArray ?? [],
  }

}
export const constructEventConfigEditDataPayload = (editData) => {
  let templateArray = [];
  for (let i = 0; i < editData?.apidetails?.length; i++) {   
    templateArray.push({
      apiId: editData?.apidetails?.[i]?.apiId,      
      sequence: parseInt(editData?.apidetails?.[i]?.sequence),      
    });
  }
  return {
    eventcode: editData?.eventcode,
    eventname: editData?.eventname,
    payloadschema: JSON.stringify(editData?.payloadschema),
    apidetails: templateArray ?? [],
  };
};
export const saveEventConfigEditType = async (reftype,seqResult) => {
  let templateArray = [];
  for (let i = 0; i < reftype?.apidetails?.length; i++) {
    templateArray.push({
      apiId: reftype?.apidetails?.[i]?.apiId?._id,
      sequence: parseInt(reftype?.apidetails?.[i]?.sequence),
    });
  }
  if(seqResult!=0){

  return {
    eventcode: seqResult,
    eventname: reftype?.eventname,
    payloadschema: reftype?.payloadschema,
    apidetails: templateArray ?? [],
  }
}
else{
  return {
    eventname: reftype?.eventname,
    payloadschema: reftype?.payloadschema,
    apidetails: templateArray ?? [],
  }
}
}