import React from "react";
import {
  Div,
  Paper,
  //Checkbox,
  H6,
  Col,
  Row,
  //Select,
  Text,
} from "qdm-component-library";
import "./style.css";
import { withAllContexts } from "../../../HOCs";
// import Chip from '@material-ui/core/Chip';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import { SelectBox } from "../../common/smartForm/component";
// import { Typography } from "@material-ui/core";
import { SelectWithLabel } from "../../../components";
class PatientCommunication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fixedOptions: [],
    };
  }
  styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
  };

  setDetailslanguage = (name, value) => {
    let { patientData } = this.props;

    if (
      patientData.practitioner_description?.communication_languages?.length > 0
    ) {
      // if (name === "communication_languages") {
      const seen = new Set();
      const filteredArr = value.filter((el) => {
        const duplicate = seen.has(el.value);
        seen.add(el.value);
        return !duplicate;
      });

      patientData.practitioner_description[name] = filteredArr;
      patientData.setPatientData({
        ...patientData,
      });
      return;
    }

    patientData.practitioner_description[name] = value;
    patientData.setPatientData({
      ...patientData,
    });
  };
  FlterOptions = (type) => {
    const { allMasters } = this.props;
    var lista = [];
    const data = allMasters?.[type];
    for (let index in data) {
      var datatemp = {
        value: data[index]?.coding?.display,
        label: data[index]?.coding?.display,
      };
      lista.push(datatemp);
    }
    return lista;
  };

  setDetails = (name, value, next, limit) => {
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    let patientData = this.props[this.props.saveto];
    patientData.practitioner_description[name] = value;
    if (patientData.practitioner_description?.error) {
      patientData.practitioner_description.error[name] = false;
    }
    patientData[this.props.setTo]({
      ...patientData,
    });
  };

  render() {
    let { practitioner_description } = this.props.patientData;
    //const { labelStyle, borderStyle } = this.styles;
    const { parent_id } = this.props;
    return (
      <Div
        id={`${parent_id}-parent-div`}
        className="pi-root">
        <Paper
          id={`${parent_id}-parent-paper`}
          className="pi-paper">
          <Div
            id={`${parent_id}-Practitioner-Description-title-div`}
          >
            <H6
              id={`${parent_id}-Practitioner-Description-title-h6`}
              className="pi-title">Practitioner Description</H6>
          </Div>
          <Row
            id={`${parent_id}-Practitioner-Description-row`}
            className="pi-actions-root">
            <Col
              id={`${parent_id}-practitioner-type-col`}
              lg={2} className="no-padding-left">
              {/* <Select
                value={
                  practitioner_description?.practitioner_type?.label ?? null
                }
                options={this.props?.allMasters?.["practitioner_type"]}
                getOptionLabel="label"
                onChangeValue={(e) => this.setDetails("practitioner_type", e)}
                // helperText={
                //   val?.error && val?.error["prefix"] ? "Is required" : ""
                // }
                // isRequired={true}
                // error={val?.error && val?.error["prefix"] ? true : false}
                label="Practitioner Type"
                showArrow
                placeholder="Type"
                // labelStyle={labelStyle}
                // inLineStyles={borderStyle}
                hoverColor="#0071F2"
              /> */}

              {/* <Typography
                style={{
                  fontSize: "12px",

                  Fontfamily: "pc_regular",
                  color: "#6F6F6F",
                }}
                variant="body1"
              >
                Practitioner Type
              </Typography>
              <SelectBox
                list={this.props?.allMasters?.["practitioner_type"] ?? []}
                placeholder={"Type"}
                disableClearable
                value={practitioner_description?.practitioner_type}
                onchange={(e, value) =>
                  this.setDetails("practitioner_type", value)
                }
              /> */}

              <SelectWithLabel
                parent_id={'practitioner-type'}
                label={"Practitioner Type"}
                required={true}
                options={this?.props?.allMasters?.["practitioner_type"] ?? []}
                disableClearable
                value={practitioner_description?.practitioner_type}
                onChange={(value) => this.setDetails("practitioner_type", value)}
                error={this?.props?.error && !practitioner_description?.practitioner_type ? true : false}
                placeholder={"Select"}
              />

            </Col>
            {/* <Col
              id={`${parent_id}-position-col`}
              lg={2} className="no-padding-left"> */}
              {/* <Select
                // value={val?.prefix?.value ?? null}
                // onChangeValue={(e) => this.setData("prefix", e, i)}
                // options={this.FlterOptions("Name Prefix")}
                // helperText={
                //   val?.error && val?.error["prefix"] ? "Is required" : ""
                // }
                // isRequired={true}
                value={practitioner_description?.position?.label ?? null}
                options={this.props?.allMasters?.["position"]}
                getOptionLabel="label"
                onChangeValue={(e) => this.setDetails("position", e)}
                // error={val?.error && val?.error["prefix"] ? true : false}
                label="Position"
                showArrow
                placeholder="Enter Position"
                labelStyle={labelStyle}
                inLineStyles={borderStyle}
                hoverColor="#0071F2"
              /> */}

              {/* <Typography
                style={{
                  fontSize: "12px",

                  Fontfamily: "pc_regular",
                  color: "#6F6F6F",
                }}
                variant="body1"
              >
                Position
              </Typography>
              <SelectBox
                list={this.props?.allMasters?.["position"] ?? []}
                placeholder={"Enter Position"}
                disableClearable
                value={practitioner_description?.position}
                onchange={(e, value) => this.setDetails("position", value)}
              /> */}

              {/* <SelectWithLabel
                parent_id={'position'}
                label={"Position"}
                options={this?.props?.allMasters?.["position"] ?? []}
                disableClearable
                value={practitioner_description?.position}
                onChange={(value) => this.setDetails("position", value)}
                placeholder={"Select"}
              />
            </Col> */}
            {/* <Col
              id={`${parent_id}-employee-type-col`}
              lg={2} className="no-padding-left"> */}
              {/* <Select
                // value={val?.prefix?.value ?? null}
                // onChangeValue={(e) => this.setData("prefix", e, i)}
                // options={this.FlterOptions("Name Prefix")}
                // helperText={
                //   val?.error && val?.error["prefix"] ? "Is required" : ""
                // }
                // isRequired={true}
                // error={val?.error && val?.error["prefix"] ? true : false}
                value={practitioner_description?.employee_type?.label ?? null}
                options={this.props?.allMasters?.["employee_type"]}
                getOptionLabel="label"
                onChangeValue={(e) => this.setDetails("employee_type", e)}
                label="Employee Type"
                showArrow
                placeholder="Enter Type"
                labelStyle={labelStyle}
                inLineStyles={borderStyle}
                hoverColor="#0071F2"
              /> */}

              {/* <Typography
                style={{
                  fontSize: "12px",

                  Fontfamily: "pc_regular",
                  color: "#6F6F6F",
                }}
                variant="body1"
              >
                Practitioner Type
              </Typography>
              <SelectBox
                list={this.props?.allMasters?.["employee_type"] ?? []}
                placeholder={"Employee Type"}
                disableClearable
                value={practitioner_description?.employee_type}
                onchange={(e, value) => this.setDetails("employee_type", value)}
              /> */}

              {/* <SelectWithLabel
                parent_id={'employee-type'}
                label={"Employee Type"}
                options={this.props?.allMasters?.["employee_type"] ?? []}
                disableClearable
                value={practitioner_description?.employee_type}
                onChange={(value) => this.setDetails("employee_type", value)}
                placeholder={"Select"}
              />

            </Col> */}
            <Col
              id={`${parent_id}-communication-languages-col`}
              lg={6} className="no-padding-left">
              <Text
                id={`${parent_id}-communication-languages-text`}
                style={{
                  color: "#6F6F6F",
                  fontSize: "12px",
                  marginBottom: "6px",
                  fontFamily: "pc_regular",
                }}
              >
                Communication Languages
              </Text>
              <Autocomplete
                id={`${parent_id}-communication-languages-autocomplete`}
                style={{ marginBottom: 30 }}
                multiple
                fullWidth
                size="small"
                getOptionLabel={(option) => option.label}
                value={practitioner_description?.communication_languages ?? []}
                options={
                  this.props?.allMasters?.["Communication_languages"] ?? []
                }
                onChange={(e, v) =>
                  this.setDetailslanguage("communication_languages", v)
                }
                renderInput={(params) => (
                  <TextField
                    id={`${parent_id}-communication-languages-textfield`}
                    style={{ height: "20px" }}
                    {...params}
                    label={false}
                    variant="outlined"
                    placeholder="Select"
                    size="small"
                  />
                )}
              />
            </Col>
          </Row>
        </Paper>
      </Div>
    );
  }
}

export default withAllContexts(PatientCommunication);
