import React from "react";
import { HoverNavBar, TopNavBar } from "../navbars";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { CommonTable, VerticalList } from "../common";
import { FactsForm } from "./factsForm";
import { AlertProps, AxiosCall } from "../../utils";
import { set } from "date-fns";
import Config from "../../config";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  addBtn: {
    float: "right",
    fontSize: "14px",
    fontWeight: 600,
  },
  content: {
    width: "98%",
    height: "calc(100vh - 64px)",
    overflow: "auto",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 56,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
    marginTop: "70px",
    marginLeft: "30px",
  },
  topNavbar: {},
  sideNavbar: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hovarNavBar: {
    display: "flex",
    position: "relative",
    top: "63px",
  },
}));
const styles = {
  outlinedButton: {
    borderColor: "#DEDEDE",
    color: "#2A60BC",
    backgroundColor: "#fff",
    borderRadius: 8,
    fontSize: 14,
    fontFamily: "pc_regular !important",
    display: "block",
    whiteSpace: "nowrap",
  },
  outlinedDelButton: {
    borderColor: "#FF4D4A",
    color: "#FF4D4A",
    backgroundColor: "#fff",
    borderRadius: 8,
    fontSize: 14,
    fontFamily: "pc_regular !important",
    display: "block",
    whiteSpace: "nowrap",
  },
  modalHeading: {
    fontSize: 16,
    color: "#2A60BC",
    // marginBottom: 10,
  },
};
export const Facts = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // -------state---------
  const [isFormOpen, setisFormOpen] = React.useState({
    isForm: false,
    isEdit: false,
    isView: false,
  });
  const [state, setState] = React.useState();
  const [facts, setFacts] = React.useState([]);
  const classes = useStyles({ props });
  const [editState, setEditState] = React.useState({
    edit: false,
    editState: {},
  });
  const [openAlert, setOpenAlert] = React.useState({
    open: false,
    index: null,
  });
  // --------events--------
  const getFacts = async (pageDetails, searchVal = "") => {
    try {
      let payload = {
        db_name: process.env.REACT_APP_DATABASE_NAME,
        queryid: process.env.REACT_APP_GET_ALL_FACTS,
        filter: {
          serachvalue: searchVal ?? "",
          page: pageDetails?.page ?? 0,
          perPage: pageDetails?.perPage ?? 10,
        },
      };
      let Resp = await AxiosCall(payload, process.env.REACT_APP_READ_QDM_QUERY);
      if (Resp) {
        setFacts(Resp);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getFactsData = async (id) => {
    try {
      let payload = {
        db_name: process.env.REACT_APP_DATABASE_NAME,
        filter: {
          factid: id,
        },
        queryid: process.env.REACT_APP_GET_SINGLE_FACTS,
      };
      let Resp = await AxiosCall(payload, process.env.REACT_APP_READ_QDM_QUERY);
      if (Resp) {
        console.log("single", Resp);
        setEditState({
          edit: true,
          editState: Resp[0],
        });
      }
    } catch (e) {
      console.error(e, "error on single read");
    }
  };
  const handleEdit = (e, row, index) => {
    getFactsData(row?.factid);
    setisFormOpen({
      isForm: false,
      isEdit: true,
      isView: false,
    });
  };

  const handlePageChange = async (currentPage, PerPage) => {
    if (PerPage) {
      setState({
        page: (currentPage - 1) * PerPage,
        perPage: PerPage,
      });
      await getFacts({ page: (currentPage - 1) * PerPage, perPage: PerPage });
    }
  };
  const handleDelete = async (del) => {
    try {
      if (del?.open) {
        let deleteData = facts[del?.index];
        let delPayload = {
          db_name: process.env.REACT_APP_DATABASE_NAME,
          entity: Config.factsDefinationEntityName,
          filter: `${Config.factsDefinationEntityName}._id == '${deleteData?._id}'`,
          metadata_dbname: process.env.REACT_APP_MetadataDB_Name,
        };
        let Resp = await AxiosCall(
          delPayload,
          process.env.REACT_APP_SOFT_DELETE
        );
        if (Resp) {
          setOpenAlert({
            ...openAlert,
            open: false,
            index: null,
          });
          if (Resp?.Code === 201) {
            getFacts();
            alert?.setSnack({
              open: true,
              severity: AlertProps.severity.success,
              msg: "Deleted Successfully",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
              tone: true,
            });
          } else {
            alert?.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: "Delete Failed",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
              tone: true,
            });
          }
        } else {
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "please contact admin",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  };
  const handleView = (e, row, index) => {
    getFactsData(row?.factid);
    setisFormOpen({
      isForm: false,
      isEdit: false,
      isView: true,
    });
  };
  const handleSearch = (searchVal) => {
    getFacts("", searchVal);
  };
  // ---------Life Cycle Hooks--------
  React.useEffect(() => {
    getFacts();
  }, []);

  return (
    <div className={classes.root}>
      {/* Your nav bars here */}
      <div className={classes.topNavbar}>
        <TopNavBar />
      </div>
      {/* Your hovar bars here */}

      <div className={classes.hovarNavBar}>
        <HoverNavBar />
      </div>

      <div className={classes.content}>
        {isFormOpen?.isForm || isFormOpen?.isEdit || isFormOpen?.isView ? (
          <FactsForm
            isFormOpen={isFormOpen}
            setisFormOpen={setisFormOpen}
            getFacts={getFacts}
            editState={editState}
            setEditState={setEditState}
          />
        ) : (
          <VerticalList
            parent_id={"consult_master"}
            addFunc={() =>
              setisFormOpen({ isForm: true, isEdit: false, isView: false })
            }
            id={"referalrights"}
            placeholder={"search code & description"}
            onSearchChange={handleSearch}
            hideSearchBar={false}
            hideFilter={true}
            // onFilterClicked={props?.onFilterClicked}
            // isFilterApplied={props?.isFilterApplied}

            TabList={[
              {
                name: "Facts",
                component: (
                  <CommonTable
                    parent_id={"consult_master"}
                    rightAlign={["View", "Action"]}
                    handleEdit={handleEdit}
                    handleView={handleView}
                    handleDelete={(e, v, i) =>
                      setOpenAlert({
                        open: true,
                        index: i,
                      })
                    }
                    // handleCheckBox={handleCheckBox}
                    Header={[
                      { label: "S.No", showSort: false, showFilter: false },
                      { label: "Fact ID", showSort: false, showFilter: false },
                      {
                        label: "Fact Name",
                        showSort: false,
                        showFilter: false,
                      },
                      { label: "Action", showSort: false, showFilter: false },
                      { label: "View", showSort: false, showFilter: false },
                    ]}
                    dataList={facts ?? []}
                    tableData={[
                      { type: ["INCRIMENT"], name: "1" },
                      { type: ["TEXT"], name: "factid" },
                      { type: ["TEXT"], name: "factname" },
                      { type: ["EDIT", "DELETE"], name: "", align: "right" },
                      { type: ["VIEW"], name: "", align: "right" },
                    ]}
                    handlepageChange={(currentPage, PerPage) =>
                      handlePageChange(currentPage, PerPage)
                    }
                    TableCount={facts?.[0]?.totalcount}
                    incrementCount={state?.page}
                    showPagination={true}
                    rowsPerPageOptions={[
                      { label: "10 Rows", value: 10 },
                      { label: "50 Rows", value: 50 },
                      { label: "100 Rows", value: 100 },
                    ]}
                  />
                ),
              },
            ]}
          />
        )}
      </div>
      {openAlert?.open && (
        <Dialog
          fullScreen={fullScreen}
          open={openAlert?.open}
          onClose={() =>
            setOpenAlert({
              ...openAlert,
              open: false,
            })
          }
          aria-labelledby="alert-dialog-title"
        >
          <DialogTitle style={styles.modalHeading} id="alert-dialog-title">
            {"Are you Sure Want to Delete ?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this record? This action cannot be
              undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ padding: "15px" }}>
            <Button
              onClick={() =>
                setOpenAlert({
                  ...openAlert,
                  open: false,
                })
              }
              color="primary"
              style={styles.outlinedButton}
              variant="contained"
            >
              No
            </Button>
            <Button
              onClick={() => handleDelete(openAlert)}
              color="primary"
              style={styles.outlinedDelButton}
              variant="contained"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};
