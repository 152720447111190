import React, { useContext, useState } from "react";
import { CommonTable, CommonTabList } from "../common";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Col,
  Div,
  H6,
  Paper,
  Row,
  TextArea,
  TextInput,
} from "qdm-component-library";
import { SelectBox } from "../common/smartForm/component";
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  AlertProps,
  AxiosCall,
  extractIdAndValue,
  factoperatorlist,
} from "../../utils";
import { AlertContext } from "../../contexts";
import Config from "../../config";

const styles = {
  labelStyle: {
    color: "#6F6F6F",
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: "#E0E0E0",
  },
  errorBorderStyle: {
    borderRadius: 8,
    border: "1px solid red",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  addBtn: {
    float: "right",
    fontSize: "14px",
    fontWeight: 600,
  },
  content: {
    width: "100%",
    height: "calc(100vh - 64px)",
    overflow: "auto",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 56,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
    //   marginTop:"60px",
    //   marginLeft:"10px",
  },
  topNavbar: {},
  sideNavbar: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hovarNavBar: {
    display: "flex",
    position: "relative",
    top: "63px",
  },
}));
export const FactsForm = (props) => {
  const alert = useContext(AlertContext);
  const classes = useStyles({ props });
  const { labelStyle, borderStyle, errorBorderStyle } = styles;

  // ---------state------------

  const [addNew, setAddNew] = React.useState(false);
  const [state, setState] = React.useState({
    factId: "",
    factName: "",
    facts: [],
  });
  const [error, setError] = React.useState(false);
  const [fact, setFact] = React.useState({
    collectionName: {},
    fieldName: {},
    displayName: "",
    controlType: {},
    queryOrGm: {},
    queryId: "",
    queryFilter: "",
    columnType: "",
    operators: [],
  });
  const [factEditIndex, setFactEditIndex] = React.useState(null);
  const [factError, setFactError] = React.useState(false);
  const [collectionList, setCollectionList] = React.useState([]);
  const [generalMaster, setGeneralMaster] = React.useState([]);
  const [fieldList, setFieldList] = React.useState([]);
  const [operator, setOperator] = useState([]);

  // ----------events-----------

  const handleFormState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };
  const handleChange = (key, value) => {
    if (key == "fieldName") {
      setFact({
        ...fact,
        [key]: value,
        columnType: value.type,
      });
    } else {
      setFact({
        ...fact,
        [key]: value,
      });
    }
  };

  const getOperators = async (id) => {
    try {
      let payload = {
        db_name: process.env.REACT_APP_DATABASE_NAME,
        queryid: factoperatorlist,
      };
      let Resp = await AxiosCall(payload, process.env.REACT_APP_READ_QDM_QUERY);
      if (Resp) {
        let result = [];
        if (Array.isArray(Resp)) {
          Resp?.map((v, i) => {
            let obj = {
              _id: v?._id,
              label: v?.label,
              value: v?.value,
            };
            result.push(obj);
          });

          setOperator(result);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getCollectionName = async () => {
    try {
      let payload = {
        db_name: process.env.REACT_APP_DATABASE_NAME,
        docType: "DOCUMENT",
        excludeDefault: false,
        metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
      };
      let Resp = await AxiosCall(
        payload,
        process.env.REACT_APP_GET_COLLECTION_LIST
      );
      if (Resp) {
        const list = [];
        Resp?.Result?.map((v, i) => {
          let obj = {
            _id: v?._id,
            label: v?.name,
            value: v?.name,
            type: v?.type,
          };
          list.push(obj);
        });
        setCollectionList(list);
      }
    } catch (e) {
      console.error(e);
    }
  };
  const getGeneralMaster = async () => {
    try {
      let payload = {
        db_name: process.env.REACT_APP_DATABASE_NAME,
        filter: {
          type: ["RULECONTROLTYPE", "RULEQUERYORGM"],
          tenantid: process.env.REACT_APP_TENANTID,
          facilityid: "",
          lang: "en",
        },
        queryid: process.env.REACT_APP_RULE_BUILDER_FACT_GM,
      };
      let Resp = await AxiosCall(payload, process.env.REACT_APP_READ_QDM_QUERY);
      if (Resp) {
        setGeneralMaster(Resp);
      }
    } catch (e) {
      console.error(e);
    }
  };
  const getFieldName = async (collectionName) => {
    try {
      let payload = {
        db_name: process.env.REACT_APP_DATABASE_NAME,
        entity: [collectionName],
        metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
      };
      let Resp = await AxiosCall(
        payload,
        process.env.REACT_APP_GET_COLLECTION_ATTRIBUTES
      );
      if (Resp) {
        const list = [];

        Resp?.Result?.[0]?.[collectionName]?.map((v, i) => {
          let obj = {
            _id: v?.columnName,
            label: v?.columnName,
            value: v?.columnName,
            type: v?.columnType,
          };
          list.push(obj);
        });
        setFieldList(list);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const saveFacts = () => {
    setFactError(true);
    if (
      !fact?.collectionName?.value ||
      !fact?.fieldName?.value ||
      !fact?.displayName ||
      !fact?.controlType?.value ||
      // !fact?.queryOrGm?.value ||
      // !fact?.queryId ||
      !fact?.columnType ||
      !fact.operators.length
    ) {
      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all the mandatory fields!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
      return;
    }
    // validation for query filter object
    if (fact?.queryFilter) {
      let stringToObj;
      try {
        stringToObj = JSON.parse(fact?.queryFilter);
        if (typeof stringToObj === "object" && !Array.isArray(stringToObj)) {
          if (Object.keys(stringToObj).length < 1) {
            alert?.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: "Object can't be empty!",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
              tone: true,
            });
            return;
          }
        } else {
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Please enter a valid query filter!",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
          return;
        }
      } catch (e) {
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please enter a valid query filter!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        return;
      }
    }
    setFactError(false);
    if (factEditIndex == null) {
      let list = [...state?.facts];
      list.push(fact);
      handleFormState("facts", list);
    } else {
      let list = [...state?.facts];
      list[factEditIndex] = fact;
      handleFormState("facts", list);
    }
    clearFacts();
    setAddNew(false);
    setFactEditIndex(null);
  };
  const clearFacts = () => {
    setFactError(false);
    setFact({
      collectionName: {},
      fieldName: {},
      displayName: "",
      controlType: {},
      queryOrGm: {},
      queryId: "",
      queryFilter: "",
      columnType: "",
      operators: [],
    });
  };

  const handleSubmit = async () => {
    setError(true);
    if (!state?.factName || !state?.facts?.length > 0) {
      if (!state?.factName) {
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please fill all the mandatory fields!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      } else {
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please fill at leat one fact!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      }
    } else {
      setError(false);
      let constructFacts = [];
      state?.facts?.map((v, i) => {
        let obj = {
          collectionname: v?.collectionName?.value,
          fieldname: v?.fieldName?.value,
          displayname: v?.displayName,
          controltype: v?.controlType?._id,

          queryorgm: v?.queryOrGm?._id,
          queryid: v?.queryId,
          queryfilter: v?.queryFilter ? JSON.parse(v?.queryFilter) : null,
          columnType: v?.columnType,
          operators: v?.operators,
        };
        constructFacts.push(obj);
      });
      let payload = {
        factid: state?.factId,
        factname: state?.factName,
        facts: constructFacts,
      };
      let filter = { filter: { factid: state?.factId ? state?.factId : "" } };
      let masterPayload = [];
      let data = state?.factId
        ? {
            db_name: process.env.REACT_APP_DATABASE_NAME,
            entity: Config.factsDefinationEntityName,
            is_metadata: true,
            metadataId: process.env.REACT_APP_METADATAID,
            metadata_dbname: process.env.REACT_APP_MetadataDB_Name,
            ...filter,
            doc: payload,
          }
        : {
            db_name: process.env.REACT_APP_DATABASE_NAME,
            entity: Config.factsDefinationEntityName,
            is_metadata: true,
            metadataId: process.env.REACT_APP_METADATAID,
            metadata_dbname: process.env.REACT_APP_MetadataDB_Name,
            // ...filter,
            doc: payload,
          };
      masterPayload.push(data);
      let Resp = await AxiosCall(
        masterPayload,
        process.env.REACT_APP_UPSERT_DOC
      );
      if (Resp.Code === 201) {
        props?.getFacts();
        props?.setisFormOpen({
          isForm: false,
          isEdit: false,
          isView: false,
        });
        setState({
          factId: "",
          factName: "",
          facts: [],
        });
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: state?.factId ? "Update Successfully" : "Saved Successfully!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      } else {
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Failed to Save!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      }
    }
  };

  const handleListEdit = (index) => {
    let getSingleFact = state?.facts[index];
    setAddNew(true);
    setFactEditIndex(index);
    setFact({
      collectionName: getSingleFact?.collectionName,
      fieldName: getSingleFact?.fieldName,
      displayName: getSingleFact?.displayName,
      controlType: getSingleFact?.controlType,
      queryOrGm: getSingleFact?.queryOrGm,
      queryId: getSingleFact?.queryId,
      queryFilter: getSingleFact?.queryFilter,
      operators: getSingleFact?.operators,
      columnType: getSingleFact?.columnType,
    });
  };
  const handleDelete = (e, data, index) => {
    let list = JSON.parse(JSON.stringify(state?.facts));
    list.splice(index, 1);
    setState({
      ...state,
      facts: list,
    });
  };

  const handleGoBack = () => {
    props?.setEditState({ edit: false, editState: {} });
    setState({
      factId: "",
      factName: "",
      facts: [],
    });
    props?.setisFormOpen({
      isForm: false,
      isEdit: false,
      isView: false,
    });
  };

  // -----------Life Cycles-----------

  React.useEffect(() => {
    getFieldName(fact?.collectionName?.value);
  }, [fact?.collectionName?._id]);

  React.useEffect(() => {
    getCollectionName();
    getGeneralMaster();
    getOperators();
  }, []);

  React.useEffect(() => {
    if (props?.editState?.edit) {
      const facts = props?.editState?.editState?.facts;
      let constructFacts = [];
      facts?.map((v, i) => {
        let obj = {
          collectionName: {
            _id: "",
            label: v?.collectionname,
            value: v?.collectionname,
          },
          fieldName: {
            _id: "",
            label: v?.fieldname,
            value: v?.fieldname,
          },
          displayName: v?.displayname,
          controlType: {
            _id: v?.controltype?._id,
            label: v?.controltype?.display,
            value: v?.controltype?.display,
          },
          columnType: v?.columnType,
          queryOrGm: {
            _id: v?.queryorgm?._id,
            label: v?.queryorgm?.display,
            value: v?.queryorgm?.display,
          },
          queryId: v?.queryid,
          operators: v?.operators,
          queryFilter: v?.queryfilter ? JSON.stringify(v?.queryfilter) : "",
        };
        constructFacts.push(obj);
      });
      setState({
        factId: props?.editState?.editState?.factid,
        factName: props?.editState?.editState?.factname,
        facts: constructFacts,
      });
    }
  }, [props?.editState]);

  return (
    <div>
      <Div>
        <CommonTabList
          parent_id={"agerange_form"}
          backbtn
          backFun={handleGoBack}
          title={
            props?.isFormOpen?.isForm
              ? "Add New Fact"
              : props?.isFormOpen?.isEdit
              ? "Edit Fact"
              : "View Fact"
          }
          list={[]}
        />
      </Div>
      <Div className="pi-paper">
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Paper className="pi-paper">
              <Div>
                <H6 className="pi-title">{"Facts Definition"}</H6>
              </Div>
              <Row>
                <Col lg={6} inLineStyles={{ padding: 10 }}>
                  <TextInput
                    value={state?.factId}
                    onChange={(e) => handleFormState("factId", e.target.value)}
                    label="Fact ID"
                    labelStyle={labelStyle}
                    placeholder=""
                    style={borderStyle}
                    disabled
                  />
                </Col>
                <Col lg={6} inLineStyles={{ padding: 10 }}>
                  <TextInput
                    value={state?.factName}
                    onChange={(e) =>
                      handleFormState("factName", e.target.value)
                    }
                    label={
                      <>
                        Fact Name<span style={{ color: "red" }}> *</span>
                      </>
                    }
                    labelStyle={labelStyle}
                    placeholder=""
                    style={
                      error && !state?.factName ? errorBorderStyle : borderStyle
                    }
                    disabled={props?.isFormOpen?.isView}
                  />
                </Col>
              </Row>
            </Paper>
          </Grid>
          <Grid item>
            <Paper className="pi-paper">
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Div>
                    <H6 className="pi-title">{"Facts"}</H6>
                  </Div>
                </Grid>
                {!props?.isFormOpen?.isView && (
                  <Grid item>
                    <Div
                    //   onClick={() => setisNew(true)}
                    >
                      <Button
                        className={classes.addBtn}
                        onClick={() => {
                          setAddNew(true);
                          clearFacts();
                        }}
                        color="primary"
                      >
                        + Add New
                      </Button>
                    </Div>
                  </Grid>
                )}
              </Grid>

              {/**Rights Access Grid view */}
              <Grid container style={{ paddingTop: "10px" }}>
                <CommonTable
                  parent_id={"category_details"}
                  handleEdit={(e, data, index) => {
                    handleListEdit(index);
                  }}
                  handleDelete={handleDelete}
                  rightAlign={["Action"]}
                  Header={[
                    "S.No",
                    "Collection Name",
                    "FieldName",
                    "Display Name",
                    "Control Type",
                    "Column Type",
                    "Query/GM",
                    "Query ID",
                    "Query Filter",

                    !props?.isFormOpen.isView && "Action",
                  ]}
                  // dataList={state?.facts}
                  dataList={state?.facts.map((item) => ({
                    ...item,

                    queryId: item?.queryId || "Null",
                    queryFilter: item?.queryFilter || "Null",
                  }))}
                  tableData={[
                    { type: ["INCRIMENT"], name: "1" },
                    {
                      type: ["TEXT"],
                      name: "collectionName",
                      optionLabel: "label",
                    },
                    { type: ["TEXT"], name: "fieldName", optionLabel: "label" },
                    { type: ["TEXT"], name: "displayName" },
                    {
                      type: ["TEXT"],
                      name: "controlType",
                      optionLabel: "label",
                    },
                    {
                      type: ["TEXT"],
                      name: "columnType",
                    },

                    { type: ["TEXT"], name: "queryOrGm", optionLabel: "label" },
                    { type: ["TEXT"], name: "queryId" },
                    { type: ["TEXT"], name: "queryFilter" },

                    !props?.isFormOpen.isView && {
                      type: ["EDIT", "DELETE"],
                      name: "",
                      align: "right",
                    },
                  ]}
                  // condition={condition}
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid item>
            {!props?.isFormOpen?.isView && (
              <Paper className="pi-paper">
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Button
                      onClick={() => {
                        clearFacts();
                        setState({ factId: "", factName: "", facts: [] });
                      }}
                    >
                      {" "}
                      Clear
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSubmit()}
                    >
                      {props?.editState?.edit ? "Update" : "save"}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            )}
          </Grid>
        </Grid>
      </Div>
      <Dialog
        open={addNew}
        maxWidth="lg"
        fullWidth
        onClose={() => setAddNew(false)}
        disableBackdropClick
      >
        <Paper className="pi-paper" style={{ padding: "30px 20px" }}>
          <Grid container justifyContent="space-between">
            <Grid item style={{ paddingLeft: "10px" }}>
              <Typography variant="h6">Add Fact </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={() => setAddNew(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Row>
            <Col lg={4} inLineStyles={{ padding: 10 }}>
              <Typography
                style={{
                  fontSize: "12px",
                  Fontfamily: "pc_regular",
                  color: "#6F6F6F",
                }}
                variant="body1"
              >
                Collection Name<span style={{ color: "red" }}> *</span>
              </Typography>
              <SelectBox
                list={collectionList ?? []}
                onchange={(e, v) => {
                  handleChange("collectionName", v);
                }}
                placeholder={"select..."}
                error={factError && !fact?.collectionName?.value}
                // // disableClearable
                // disabled={
                //   props?.isFormOpen?.isView || props?.isFormOpen?.isEdit
                // }
                required={true}
                value={fact?.collectionName}
              />
            </Col>
            <Col lg={4} inLineStyles={{ padding: 10 }}>
              <Typography
                style={{
                  fontSize: "12px",
                  Fontfamily: "pc_regular",
                  color: "#6F6F6F",
                }}
                variant="body1"
              >
                Field Name<span style={{ color: "red" }}> *</span>
              </Typography>
              <SelectBox
                list={fieldList ?? []}
                onchange={(e, v) => {
                  handleChange("fieldName", v);
                }}
                placeholder={"select..."}
                // // disableClearable
                required={true}
                value={fact?.fieldName}
                // disabled={
                //   props?.isFormOpen?.isView || props?.isFormOpen?.isEdit
                // }
                error={factError && !fact?.fieldName?.value}
              />
            </Col>
            <Col lg={4} inLineStyles={{ padding: 10 }}>
              <TextInput
                value={fact?.displayName}
                onChange={(e) => handleChange("displayName", e.target.value)}
                label={
                  <>
                    Display Name<span style={{ color: "red" }}> *</span>
                  </>
                }
                labelStyle={labelStyle}
                placeholder=""
                style={
                  factError && !fact?.displayName
                    ? errorBorderStyle
                    : borderStyle
                }
              />
            </Col>
            <Col lg={4} inLineStyles={{ padding: 10 }}>
              <Typography
                style={{
                  fontSize: "12px",
                  Fontfamily: "pc_regular",
                  color: "#6F6F6F",
                }}
                variant="body1"
              >
                Control Type<span style={{ color: "red" }}> *</span>
              </Typography>
              <SelectBox
                list={extractIdAndValue("RULECONTROLTYPE", generalMaster)}
                placeholder={"select..."}
                // // disableClearable
                required={true}
                value={fact?.controlType}
                // disabled={
                //     props?.isFormOpen?.isView || props?.isFormOpen?.isEdit
                // }
                error={factError && !fact?.controlType?.value}
                onchange={(e, v) => {
                  handleChange("controlType", v);
                }}
              />
            </Col>
            <Col lg={4} inLineStyles={{ padding: 10 }}>
              <TextInput
                value={fact?.columnType}
                label={
                  <>
                    Column Type
                    <span style={{ color: "red", border: "none" }}> *</span>
                  </>
                }
                labelStyle={labelStyle}
                placeholder=""
                style={
                  factError && !fact?.columnType
                    ? errorBorderStyle
                    : borderStyle
                }
                disabled
              />
            </Col>

            <Col lg={4} inLineStyles={{ padding: 10 }}>
              <Typography
                style={{
                  fontSize: "12px",
                  Fontfamily: "pc_regular",
                  color: "#6F6F6F",
                }}
                variant="body1"
              >
                Query/GM
                {/* <span style={{ color: "red" }}> *</span> */}
              </Typography>
              <SelectBox
                list={extractIdAndValue("RULEQUERYORGM", generalMaster)}
                placeholder={"select..."}
                // // disableClearable
                required={true}
                value={fact?.queryOrGm}
                // disabled={
                //   props?.isFormOpen?.isView || props?.isFormOpen?.isEdit
                // }
                // error={factError && !fact?.queryOrGm?.value}
                onchange={(e, v) => {
                  handleChange("queryOrGm", v);
                }}
              />
            </Col>
            <Col lg={4} inLineStyles={{ padding: 10 }}>
              <TextInput
                value={fact?.queryId}
                onChange={(e) => {
                  handleChange("queryId", e.target.value);
                }}
                label={
                  <>
                    Query Id
                    {/* <span style={{ color: "red" }}> *</span> */}
                  </>
                }
                labelStyle={labelStyle}
                placeholder=""
                // style={
                //   factError && !fact?.queryId ? errorBorderStyle : borderStyle
                // }
                // disabled={fact?.queryOrGm?.value === "GM"}
              />
            </Col>
            <Col lg={8} inLineStyles={{ padding: 10 }}>
              <Typography
                style={{
                  fontSize: "12px",
                  Fontfamily: "pc_regular",
                  color: "#6F6F6F",
                  marginBottom: "5px",
                }}
                variant="body1"
              >
                Operators<span style={{ color: "red" }}> *</span>
              </Typography>

              <Autocomplete
                fullWidth
                size="small"
                options={operator}
                value={fact?.operators ?? []}
                onChange={(e, value) => handleChange("operators", value)}
                getOptionLabel={(option) => option.label}
                multiple
                onBlur={() => {
                  if (!fact?.operators?.length) {
                    setFactError(true);
                  }
                }}
                onFocus={() => {
                  setFactError(false);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    name="example"
                    variant="outlined"
                    style={
                      factError && !fact?.operators?.length
                        ? errorBorderStyle
                        : borderStyle
                    }
                  />
                )}
              />
            </Col>

            <Col lg={12} inLineStyles={{ padding: 10 }}>
              <TextArea
                value={fact?.queryFilter}
                onChange={(e) => {
                  handleChange("queryFilter", e.target.value);
                }}
                label={"Query Filter"}
                labelStyle={labelStyle}
                placeholder='eg - {
                    "name": "Bob",
                    "age": 25,
                    "status": true,
                    "hobbies": [ "Reading","Coding","Hiking" ],
                    "address": {
                        "street": "123 Main St",
                        "city": "Anytown",
                        "state": "CA",
                        "zip": "12345"
                    }
                }'
                multiline
                rows={8}
                style={borderStyle}
              />
            </Col>
          </Row>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button onClick={clearFacts}> Clear</Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={saveFacts}>
                Add
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
    </div>
  );
};
