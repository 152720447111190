import React, { Component } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { EditorState, convertToRaw, convertFromRaw, Modifier } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import "./style.css";

const insertCharacter = (characterToInsert, editorState) => {
  const currentContent = editorState.getCurrentContent(),
    currentSelection = editorState.getSelection();

  const newContent = Modifier.replaceText(
    currentContent,
    currentSelection,
    characterToInsert
  );

  const newEditorState = EditorState.push(
    editorState,
    newContent,
    "insert-characters"
  );

  return newEditorState;

  // return EditorState.forceSelection(
  //   newEditorState,
  //   newContent.getSelectionAfter()
  // );
};

export class RichText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
    };
  }

  componentDidMount() {
    this.setState({
      // editorState: convertFromRaw(this.props.initialValue)
      editorState: EditorState.moveSelectionToEnd(
        EditorState.createWithContent(stateFromHTML(this.props.initialValue))
      ),
    });
  }

  getButtonAction = (text, createFieldMentions = false) => {
    const contentState = this.state.editorState.getCurrentContent();
    const selection = this.state.editorState.getSelection();

    let newContentState = Modifier.insertText(contentState, selection, text);

    const newEditorState = EditorState.push(
      this.state.editorState,
      newContentState,
      "insert-characters"
    );

    this.setState({
      editorState: EditorState.forceSelection(
        newEditorState,
        newContentState.getSelectionAfter()
      ),
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    const prevEditorState = this.props.initialValue;
    const nextEditorState = nextProps.initialValue;
    if (prevEditorState !== nextEditorState) {
      // If Empty initial value provided then update the editor state with empty content.
      if (!Boolean(nextEditorState)) {
        this.setState({
          editorState: EditorState.createWithContent(
            stateFromHTML(nextEditorState)
          ),
        });
      }
    }

    const prevAppendValue = this.props.appendValue;
    const nextAppendValue = nextProps.appendValue;

    if (prevAppendValue !== nextAppendValue) {
      if (Boolean(nextAppendValue)) {
        const newEditorState = insertCharacter(
          nextAppendValue,
          this.state.editorState
        );
        this.setState(
          {
            editorState: newEditorState,
          },
          () => {
            this.props.onChange(
                stateToHTML(this.state.editorState.getCurrentContent())
              );
            this.props.resetAppendValue();
          }
        );
      }
    }

    return true;
  }

  onEditorStateChange = (editorState) => {
    this.setState(
      {
        editorState,
      },
      () => {
        // const currentContent = editorState.getCurrentContent()

        // const raw = convertToRaw(editorState.getCurrentContent());
        const html = stateToHTML(editorState.getCurrentContent());
        this.props.onChange(html);
      }
    );
  };

  render() {
    const { withDirectionTags, name } = this.props;
    const { editorState } = this.state;
    const customToolbarOptions = {
        options: name === "Body" ? ["inline", "list"] : [],
        inline: {
            options: [
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "superscript",
            "subscript",
            ],
            className: "rtf-toolbar-inline",
        },
        list: {
            options: ["unordered", "ordered"],
            className: "rtf-toolbar-list",
        },
    };
    return (
      <>
        <Editor
          placeholder={this.props.placeholder}
          editorState={editorState}
          toolbar={customToolbarOptions}
          toolbarClassName={
            name === "Body" ?
             withDirectionTags
              ? "rtf-toolbar-with-direction-control"
              : "rtf-toolbar" : null
          }
          wrapperClassName={
            name === "Body" ? (withDirectionTags ? "rtf-wrapper-with-direction-body" : "rtf-wrapper-body") :
            (withDirectionTags ? "rtf-wrapper-with-direction" : "rtf-wrapper")
          }
          wrapperStyle={{
            border: this?.props?.error ? "1px solid red" : "2px solid #d3d3d3"
          }}
        // wrapperClassName={
        //     withDirectionTags ? "rtf-wrapper-with-direction" : "rtf-wrapper"
        //   }
          editorClassName="rtf-editor"
          onEditorStateChange={this.onEditorStateChange}
        />
        {/* {withDirectionTags && ( */}
          <div
            style={{
              // display: "flex",
              border: "2px solid #d3d3d3",
              borderTop: "0px",
              padding: "8px",
              borderRadius: "8px",
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              rowGap:"5px"
              // justifyContent: this?.props?.schemaVal?.length > 0 ? "" : "center"

            }}
          >
           {this?.props?.schemaVal?.length > 0 ? (
            this?.props?.schemaVal?.map((v, i) => (
                <Button
                    key={i} // Add a unique key for each button
                    style={{
                    marginLeft: "10px",
                    backgroundColor: "#DEE5EC",
                    color: "#2A3C50",
                    fontSize: "12px",
                    border: "none",
                    marginTop: "7px",
                    }}
                    onClick={() => name === "Body" ||  name === "Subject" ? this.getButtonAction(`#${v?.label}#`) : this.getButtonAction(`#${v?.label}#,`)}
                >
                    {v?.label}
                </Button>
            ))
           ) : (
            <Grid >
                <Typography style={{
                    color: "#6F6F6F",
                    fontSize: "13px",
                    marginBottom: "6px",
                    fontFamily: `pc_regular !important`,
                    textAlign: "center"
                }}>
                    No Data Found!
                </Typography>
            </Grid>
           )}
         
            {/* <Button
              style={{
                marginLeft: "10px",
                backgroundColor: "#DEE5EC",
                color: "#2A3C50",
                fontSize: "12px",
                border: "none",
              }}
              onClick={() => this.getButtonAction("<Frequency>")}
            >
              Frequency
            </Button>
            <Button
              style={{
                marginLeft: "10px",
                backgroundColor: "#DEE5EC",
                color: "#2A3C50",
                fontSize: "12px",
              }}
              onClick={() => this.getButtonAction("<Route>")}
            >
              Route
            </Button>
            <Button
              style={{
                marginLeft: "10px",
                backgroundColor: "#DEE5EC",
                color: "#2A3C50",
                fontSize: "12px",
              }}
              onClick={() => this.getButtonAction("<Order Dose>")}
            >
              Order Dose
            </Button>
            <Button
              style={{
                marginLeft: "10px",
                backgroundColor: "#DEE5EC",
                color: "#2A3C50",
                fontSize: "12px",
              }}
              onClick={() => this.getButtonAction("<Admin Qty>")}
            >
              Admin Qty
            </Button> */}
          </div>
    
      </>
    );
  }
}
