import {
  Button,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  Col,
  Div,
  H6,
  Paper,
  Row,
  TextInput,
} from "qdm-component-library";
import React, { useContext, useEffect, useState } from "react";
import { CommonTabList, CommonTable, GenerateForm } from "../../../components";
import { AlertProps, AxiosCall, EventEntity, getApiDetail, isEmptyObject } from "../../../utils";
import { AlertContext } from "../../../contexts";
import {
  constructEventConfigEditDataPayload,
  saveEventConfigEditType,
} from "../../../screens/availabilityManagement/timeTableManagement/TimeTableManagementForm/constructJson";
import Ajv from "ajv";

const styles = {
  labelStyle: {
    color: "#6F6F6F",
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: "#E0E0E0",
  },
  errorBorderStyle: {
    borderRadius: 8,
    border: "1px solid red",
  },
};
const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
  },
  addBtn: {
    float: "right",
    fontSize: "14px",
    fontWeight: 600,
  },
  title: {
    fontFamily: "poppinsemibold",
    display: "inline",
    fontSize: "12px",
  },
  form: {
    paddingTop: 15,
  },
}));

const EventForm = (props) => {
  const alert = useContext(AlertContext);
  const classes = useStyles();
  const axios = require("axios");
  const [options, setOptions] = useState([]);

  const [state, setState] = React.useState({
    open: false,
    saveloading: false,
    edit_data: false,
    editIndex: null,
  });
  const [error, setError] = useState({});
  const [reftype, setReftype] = useState({
    eventcode: "",
    eventname: "",
    payloadschema: "",
    apidetails: [],
  });
  const [refTemplates, setRefTemplates] = useState({
    apiId:{},
    sequence:"",
    index:""
  })
  const handleDrawerClose = () => {
    setState({ ...state, open: false, editIndex: null });
  };
  const getOptions = async () => {
    let data = {
      db_name: process.env.REACT_APP_METADATA_DB_NAME,
      queryid: getApiDetail,
    };
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_READ_QDM_QUERY,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        setOptions(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleFormState = (name, val) => {
    setReftype({
      ...reftype,
      [name]:val,
    })
  };
  const validation = () => {
    let data = {
      eventname: "",
    };
    let validation = {
      error: false,
    };

    Object.keys(data).forEach((v) => {
      if (typeof data[v] === "string" ) {
        if (!reftype[v]?.trim()) {
          validation.error = true;
          validation[v] = true;
        } else {
          validation[v] = false;
        }
      } else if (Array.isArray(data[v])) { 
        if (reftype[v].length === 0) {
          validation.error = true;
          validation[v] = true;
        } else {
          validation[v] = false;
        }
      } else if (typeof data[v] === "object") {
        if (isEmptyObject(reftype[v])) {
          validation.error = true;
          validation[v] = true;
        } else {
          validation[v] = false;
        }
      }
       else {
        if (
          !reftype[v] ||
          (typeof reftype[v] === "object" && isEmptyObject(state[v]))
        ) {
          validation.error = true;
          validation[v] = true;
        } else {
          validation[v] = false;
        }
      }
    });
    setError(validation);
    return validation;
  };
  const onSubmit = async () => {      
    try {
      let valid = validation();
      if (!valid?.error) {  
        if(reftype?.payloadschema=="" || reftype?.payloadschema){
          try{
              const ajv = new Ajv();
              const schema = reftype?.payloadschema==""?"":JSON.parse(reftype?.payloadschema);
              const draftSchema = {
              "$id": "http://json-schema.org/draft-04/schema#",
              "type": "object",
              "properties": {
              "type": { "type": "string" },
              "properties": { "type": "object" },
              "required": { "type": "array" }
            }
          };
            ajv.addSchema(draftSchema, "http://json-schema.org/draft-04/schema#");

          const hasSchemaStructure = schema?.type === "object" && schema?.hasOwnProperty("properties") &&
          Object.keys(schema?.properties).every(key => typeof schema.properties[key] === "object");
          const isValidSchema = hasSchemaStructure && ajv.validateSchema(schema);

          if (!isValidSchema && reftype?.payloadschema!="") {
                    alert.setSnack({
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Invalid Schema",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.right,
                });
              return
            }
            } catch (e) {
            console.error("Error parsing schema input or validating schema:", e.message);
            alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Invalid Schema",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            });
            return
          }                  
        } 
        else{
          alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Invalid Schema",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
        });
      return
        }  
        if (!reftype?.apidetails || !reftype?.apidetails.length) {
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Please add at least one api detail before saving",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          return;
        }
        let seqResult = 0;
        if(editData?._id==null || editData?._id==undefined){
        let seqGenPayload = {
          db_name: process.env.REACT_APP_METADATA_DB_NAME,
          doc: {eventcode:""},
          entity: EventEntity,
          fieldname: "eventcode",
          is_metadata: "false"
         }
          let Resp = await AxiosCall(seqGenPayload,"https://arangodbservice.dev.ainqaplatform.in/api/generate_sequence");
          seqResult = Resp?.Result[0]?.Lastvalue;
        }
        const save2 = await saveEventConfigEditType(reftype,seqResult);
        let filter = { filter: { _id: editData?._id } };
        let payload = [
          {
            db_name: process.env.REACT_APP_METADATA_DB_NAME,
            entity: EventEntity,
            is_metadata: false,
            ...filter,
            doc: save2,
          },
        ];
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: process.env.REACT_APP_UPSERT_DOC,
          headers: {
            "Content-Type": "application/json",
          },
          data: payload,
        };
        axios
          .request(config)
          .then((response) => {
            if (response?.data?.Code === 201) {
              alert?.setSnack({
                open: true,
                severity: AlertProps.severity.success,
                msg: editData?._id
                  ? "Event Configuration Updated Successfully"
                  : "Event Configuration Saved Successfully",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
                tone: true,
              });
              closeForm();
            } else if (response?.data?.Code === 1111) {
              alert?.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg:
                  "Event Configuration Already Exists" ||
                  response?.data?.validation_error,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
                tone: true,
              });
              return;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (valid.error) {
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please fill mandatory fields",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
        return;
      }
    } catch (error) {
      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.warning,
        msg: error?.message || "something went wrong",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
  };
  const handleDelete = (e, data, index) => {
    let updatedReftype = reftype;
    let list = reftype?.apidetails;
    if (list.length === 1) {
      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "At least one record must remain in the apidetails.",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
      return;
    } else {
      list.splice(index, 1);
      updatedReftype.apidetails = list;
      setReftype(updatedReftype);
    }
  };
  const save = async (data) => {
    try {
      if (data) {

        let seq = Number(refTemplates?.sequence);
        if (seq >= 1 && seq <= 20) {
          if (!String(refTemplates?.index)) {
            if (
              reftype?.apidetails?.some(
                (item) =>
                  item?.apiId === refTemplates?.apiId ||
                  item?.sequence === refTemplates?.sequence
              )
            ) {
              alert?.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: "API Details Already Exist",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
                tone: true,
              });
              return;
            } else {
              reftype?.apidetails.push(refTemplates);
              setReftype({ ...reftype, apidetails: reftype?.apidetails });
            }
          } else {
            if( reftype?.apidetails?.filter(
              (item, i) =>
               ( item?.apiId?._id === refTemplates?.apiId?._id ||
                item?.sequence == refTemplates?.sequence) && i !== refTemplates?.index
            ).length > 0){
              alert?.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: "API Details Already Exist",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
                tone: true,
              });
              return;
            } else {
              let list = reftype?.apidetails;
              let tempObj = {
                apiId: refTemplates?.apiId,
                sequence: refTemplates?.sequence,
              };
              list[refTemplates?.index] = tempObj;
              const updatedReftype = {
                ...reftype,
                apidetails: list,
              };
              setReftype(updatedReftype);
            }
            }
        } else {
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Template Sequence Should be between 1 to 20",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          return;
        }
      }
      setState({
        open: false,
        saveloading: false,
        edit_data: false,
        editIndex: null,
      });
    } catch (error) {     
      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.warning,
        msg: error?.message || "something went wrong",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
    handleDrawerClose();
  };
  const handleChangeTemplates = (type, val, value) => {
   if(type === "apiId"){
        setRefTemplates({
          ...refTemplates, apiId: value
        })
   }else if (type === "sequence"){
        setRefTemplates({
          ...refTemplates, sequence: value
        })
   }
  }
  const handleEditTemplate = (e,data,index) =>{
    {
      setRefTemplates({
        ...refTemplates,
        apiId: data?.apiId,
        sequence: data?.sequence,
        index:index
      })
    }
    setState({
      ...state,
      editIndex: index,
      edit_data: true,
      open: true,
    });
  }
  useEffect(() => {
    getOptions();
  }, []);
  useEffect(() => {
    if (editData) {
      const refedit = constructEventConfigEditDataPayload(editData);
      setReftype({
        eventcode: refedit?.eventcode,
        eventname: refedit?.eventname,
        payloadschema: JSON.parse(refedit?.payloadschema),
        apidetails: refedit?.apidetails,
      });
    }
  }, []);

  const { labelStyle, borderStyle, errorBorderStyle } = styles;
  const { parent_id, closeForm, editData, isView } = props;

  return (
    <React.Fragment>
      <CommonTabList
        parent_id={"timetableform"}
        backbtn
        backFun={closeForm}
        title={
          isView
            ? "View Event Configuration"
            : editData
            ? "Edit Event Configuration"
            : "Add Event Configuration"
        }
        list={[]}
      />
      <Div
        id={`${parent_id}-parent-div`}
        className="pi-root"
        style={{ margin: "20px" }}
      >
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Paper id={`${parent_id}-parent-paper`} className="pi-paper">
              <Div id={`${parent_id}-parent-sub-div`}>
                <H6 id={`${parent_id}-title-h6`} className="pi-title">
                  {"EVENT CONFIGURATION"}
                </H6>
              </Div>
              <Row>
                <Col
                  md={2}
                  lg={3}
                  sm={6}
                  xs={12}
                  inLineStyles={{ padding: 10 }}
                >
                  <H6
                        style={{
                        display: "flex",
                        alignItems: "center",
                        margin: 0,
                        flex: 1,
                        fontSize: "12px",
                        fontWeight:"600"
                        }}
                        >
                        Event Code
                        </H6>
                  <TextInput
                    value={reftype?.eventcode}
                    onChange={(e) => { {
                        handleFormState("eventcode",e.target.value);
                      }
                    }}
                    labelStyle={labelStyle}
                    placeholder=""
                    disabled
                    style={error?.eventcode ? errorBorderStyle : borderStyle}
                  />
                </Col>
                <Col
                  md={2}
                  lg={7}
                  sm={6}
                  xs={12}
                  inLineStyles={{ padding: 10 }}
                >
                  <H6
                        style={{
                        display: "flex",
                        alignItems: "center",
                        margin: 0,
                        flex: 1,
                        fontSize: "12px",
                        fontWeight:"600"
                        }}
                        >
                        Event Name<span style={{ color: "red" }}> *</span>
                        </H6>
                  <TextInput
                    value={reftype?.eventname}
                    onChange={(e) => {
                        handleFormState("eventname",e.target.value);
                    }}
                    isRequired={true}
                    placeholder="Enter Name"
                    labelStyle={labelStyle}
                    error={error && !reftype?.eventname}
                    disabled={isView ? true : false}
                    style={error?.eventname ? errorBorderStyle : borderStyle}
                  />
                </Col>
              </Row>
              <Row>
                 <Col
                  md={2}
                  lg={10}
                  sm={6}
                  xs={12}
                  inLineStyles={{ padding: 10 }}
                >
                  <H6
                        style={{
                        display: "flex",
                        alignItems: "center",
                        margin: 0,
                        flex: 1,
                        fontSize: "12px",
                        fontWeight:"600"
                        }}
                        >
                        API Schema
                        </H6>
                  <TextField
                        value={
                          Object.keys(reftype.payloadschema)?.length > 0 ? reftype?.payloadschema : ""
                        }
                        onChange={(e) => {                         
                            handleFormState("payloadschema",e.target.value);
                        }}
                        placeholder="Enter Schema"
                        labelStyle={labelStyle}
                        disabled={isView ? true : false}
                        multiline
                        minRows={4}
                        variant="outlined"
                        style={{ width: "100%" }}                           
                        />
                </Col>
              </Row>
            </Paper>
          </Grid>
          <Grid item>
            <React.Fragment>
              <Paper id={`${parent_id}-parent-paper`} className="pi-paper">
                <Typography
                  id={`${parent_id}_AGE_CATEGORY_typography`}
                  className={classes.title}
                  variant="h6"
                >
                  API DETAILS
                </Typography>
                {isView ? "" : <Button
                  id={`${parent_id}_Add_New_button`}
                  className={classes.addBtn}
                  onClick={() => {
                    setRefTemplates({
                      apiId: {},
                      sequence: "",
                      index: "",
                    });
                    setState({ ...state, open: true });
                  }}
                  disabled={isView}
                  color="primary"
                >
                  + Add New
                </Button>
                }
                <Grid
                  id={`${parent_id}_table_grid`}
                  container
                  className={classes.form}
                >
                  <CommonTable
                    parent_id={"category_details"}
                    handleEdit={(e, data, index) =>
                      handleEditTemplate(e, data, index)
                    }
                    handleDelete={handleDelete}
                    rightAlign={["Action"]}
                    Header={["API Name", "Sequence",isView?null:"Action"]}
                    dataList={reftype?.apidetails ?? []}
                    tableData={[
                      {
                        type: ["TEXT"],
                        name: "apiId",
                        optionLabel: "label",
                      },
                      { type: ["TEXT"], name: "sequence" },
                      isView?"":{ type: ["EDIT", "DELETE"], name: "", align: "right" },
                    ]}
                  />
                  <GenerateForm
                    parent_id={"category_details"}
                    nocancel
                    loading={state.saveloading}
                    open={state.open}
                    save={(data) => {
                      save(data);
                    }}
                    handlecancel={handleDrawerClose}
                    onchangefun={(type, val, value) => {
                      handleChangeTemplates(type, val, value);
                    }}
                    header={
                      state.editIndex !== null
                        ? "Edit API Details"
                        : "Add API Details"
                    }
                    json={[
                      {
                        componet_type: "select",
                        required: true,
                        label: "API Name",
                        state_name: "apiId",
                        options:
                          options ?? [],
                        disabled: isView ? true : false,
                        value: refTemplates?.apiId ?? "",
                      },
                      {
                        componet_type: "text",
                        label: "Sequence",
                        state_name: "sequence",
                        type: "number",
                        required: true,
                        disabled: isView ? true : false,
                        value: refTemplates?.sequence ?? "",
                      },
                    ]}
                  />
                </Grid>
              </Paper>
            </React.Fragment>
          </Grid>
          {isView ? null : (
            <Grid
              id={"agerange-form-rangedetails-grid"}
              style={{ padding: "20px" }}
            >
              <Button
                id={"agerange_form_rangedetails_Save_button"}
                onClick={onSubmit}
                style={{ float: "right" }}
                variant="contained"
                color="primary"
              >
                {editData ? "Update" : "Save"}
              </Button>
              <Button
                id={"agerange_form_rangedetails_Cancel_button"}
                onClick={closeForm}
                style={{ float: "right", marginRight: "20px" }}
                variant="outlined"
              >
                Cancel
              </Button>
            </Grid>
          )}
        </Grid>
      </Div>
    </React.Fragment>
  );
};

export default EventForm;
