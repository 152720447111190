import React from "react";
import {
  Div,
  Paper,
  TextInput,
  H6,
  Col,
  Row,
  Upload,
  Text,
  Image,
  Button,
} from "qdm-component-library";
import "../../practitionermaster/addPractotioner/style.css";
import { withAllContexts } from "../../../HOCs";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Switch, Checkbox } from "@material-ui/core";
import PatientCotactPoint from "../addPractotioner/contactPoint";
import Submitrole from "./submitrole";
import { AlertProps, checkMaxDateValidation } from "../../../utils";
import { Filenameui } from "../addPractotioner/commonUI";
import { uploadImage } from "../../../utils";
import { SelectBox } from "../../common/smartForm/component";
import { Typography } from "@material-ui/core";
import moment from "moment";
import { actions } from "primary_care_admin_binder";
import { connect } from "react-redux";
import CustomInputDatePicker from "../../common/datePicker/inputDatePicker";

//const optiondata = [{ value: "data" }];
class Practitionerbyrole extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Showfilter: this.props?.[this.props.saveto]?.names,
      limited: false,
      Collapse: true,
      addCollapseForm:
        this.props[this.props.saveto].practitionerbyrole?.length > 0
          ? false
          : true,
      status:true,
      contact: [{}],
    };
  }
  IOSSwitch = withStyles((theme) => ({
    root: {
      width: 37,
      height: 21,
      padding: 0,
      margin: theme.spacing(1),
      marginTop: "-2px",
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#0071F2",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 18,
      height: 18,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });
  styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    labelStyleDes: {
      color: "#6F6F6F",
      fontSize: "9px",
      fontFamily: `pc_regular !important`,
      marginLeft: "10px",
      marginTop: "3px",
    },
    borderStyle: {
      borderRadius: 8,
      border: "1px solid #E0E0E0",
    },
    errorBorderStyle: {
      borderRadius: 8,
      border: "1px solid red",
    },
    containedButton: {
      backgroundColor: "#0071F2",
      borderColor: "#0071F2",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 14,
      fontFamily: "pc_medium !important",
    },
  };
  onChangeState = (key, value, limit) => {
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    if (key === "start_date") {
      this.state["end_date"] = "";
    }
    // GET_ENTITY_BY_ID
    if (key === "select_entity") {
      this.props.GET_ENTITY_BY_ID({ id: value.value })
      this.state["entity_name"] = {}
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
  };
  validateCheck = () => {
    // const contactVal = () => {
    //   if(this.state.contact?.length === 0){
    //     return true;
    //   }else{
    //      let error = this.state.contact.map(val => val.mobile?.length > 0 ? true : false );
    //      return !error.includes(false);
    //   }
    // }

    if (this.state?.start_date  && this.state?.end_date) {
      let maxDateCheck = checkMaxDateValidation(this.state?.start_date, this.state?.end_date);
      if (maxDateCheck) {
        return maxDateCheck;
      }
    }

    if (this?.state?.speciality?._id && this?.state?.select_role?._id && this?.state?.select_entity?._id && this?.state?.entity_name?._id && this.state?.start_date  && this.state?.end_date) {
      return false;
    } else {
      let errors = {
        speciality: this.state?.speciality?._id ? false : true,
        select_role: this.state?.select_role?._id ? false : true,

        select_entity: this.state?.select_entity?._id ? false : true,
        entity_name: this.state?.entity_name?._id ? false : true,
        start_date: this.state?.start_date ? false : true,
        end_date: this.state?.end_date ? false : true,
      };
      this.setState({ ...this.state, erorr: errors });
      return true;
    }
  };

  sequenceValidation(data) {
    if ((this.state?.editindex || this.state?.editindex === 0) &&
      this.state?.editindex !== null) {
      let dummyData = this.props?.patientData?.practitionerbyrole;

      dummyData.splice(this.state?.editindex, 1)
      if (dummyData?.length > 0) {
        return dummyData?.some((v) => { return v?.speciality?.value === this.state?.speciality?.value })
      } else {
        return false;
      }
    } else {
      if (data?.length > 0) {
        return data?.some((v) => { return v?.speciality?.value === this.state?.speciality?.value })
      } else {
        return false;
      }
    }
  }

  saveAddress = () => {
    let checkValid = this.validateCheck()
    if (typeof checkValid === "string") {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: checkValid,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      }); 
      return false
    } else if (checkValid){
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please Fill Practitioner By Role Mandatory Fields!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return false;
    }

    let patientData = this.props[this.props.saveto];
    // if(Array.isArray(patientData?.practitionerbyrole)){
    if (patientData?.practitionerbyrole && patientData?.practitionerbyrole?.length > 0) {
      // if(this.state?.editindex){
      if (!this.sequenceValidation(patientData?.practitionerbyrole)) {
        if (
          (this.state?.editindex || this.state?.editindex === 0) &&
          this.state?.editindex !== null
        ) {
          patientData.practitionerbyrole[this.state?.editindex] = this.state;
        } else {
          patientData.practitionerbyrole.push(this.state);
        }
      } else {
        this.props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please select unique Specialty",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        return false;
      }
      // }
    } else {
      if (
        (this.state?.editindex || this.state?.editindex === 0) &&
        this.state?.editindex !== null
      ) {
        patientData.practitionerbyrole[this.state?.editindex] = this.state;
      } else {
        patientData.practitionerbyrole.push(this.state);
      }
    }
    patientData[this.props.setTo]({
      ...patientData,
    });
    this.props.GET_ENTITY_BY_ID({ id: "" })
    this.Clear();
    // }
  };

  Clear = () => {
    this.setState({
      select_role: null,
      speciality: null,
      select_entity: null,
      entity_name: null,
      select_location: null,
      start_date: null,
      end_date: null,
      addCollapseForm: false,
      contact: [{}],
      primary_specialty: false,
      status: true,
      erorr: {},
    });
    this.props.GET_ENTITY_BY_ID({ id: "" })
  };

  FlterOptions = (type) => {
    const { allMasters } = this.props;
    var lista = [];
    const data = allMasters?.[type];
    for (let index in data) {
      var datatemp = {
        value: data[index]?.coding?.display,
      };
      lista.push(datatemp);
    }
    return lista;
  };
  OpenAddress = async (e) => {
    this.setState({
      addAddress: e?.currentTarget ?? null,
    });
  };
  handleClear = () => {
    this.setState({
      editadd: {},
      editindex: null,
      select_role: null,
      speciality: null,
      select_entity: null,
      entity_name: null,
      select_location: null,
      start_date: null,
      end_date: null,
      addCollapseForm: false,
      contact: [{}],
      primary_specialty: false,
      status: true,
      erorr: {},
    });
  };

  Collapse(e) {
    this.setState({
      Collapse: !this.state.Collapse,
    });
  }

  addCollapse(e) {
    this.setState({
      addCollapseForm: !this.state.addCollapseForm,
      Collapse: true,
    });
  }

  onedit = async (v, i, e) => {
    this.setState({
      ...this.state,
      ...v,
      editadd: { ...v },
      editindex: i,
      erorr: {},
    });
    if (v?.select_entity?.value) {
      this.props.GET_ENTITY_BY_ID({ id: v?.select_entity?.value })
    }
    this.addCollapse(e);
  };

  ondelete = (v, i) => {
    let patientData = this.props[this.props.saveto];
    patientData.practitionerbyrole.splice(i, 1);
    patientData[this.props.setTo]({
      ...patientData,
    });
    this.Clear();
  };

  setCustomContact = (name, value, index) => {

    if (name === "mode") {
      this.state.contact[index]["mobile"] = "";
    }
    this.state.contact[index][name] = value;
    this.setState({
      ...this.state,
    });
  };

  addNewContact = () => {
    this.state.contact.push({});
    this.setState({
      ...this.state,
    });
  };

  deleteContect = (index) => {
    // let contact = this.state.contact.splice(0, index)
    this.state.contact.splice(index, 1);
    this.setState({
      ...this.state,
      // contact: contact
    });
  };

  setDataUpload = async (name, value, next, limit) => {
    var UploadedFiles = [];
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    UploadedFiles = await uploadImage([value]);
    this.onChangeState(name, UploadedFiles);
  };


  checkSpecialty = (data) => {
    if (data?.length > 0 && !this?.state?.editadd?.primary_specialty) {
      let view = data?.map(val => val?.primary_specialty)?.includes(true)
      return view
    } else {
      return false
    }
  }


  render() {
    let { addCollapseForm, Collapse } = this.state;
    const { practitionerbyrole } = this.props[this.props.saveto];
    const {
      labelStyle,
      borderStyle,
      containedButton,
      //errorBorderStyle,
      labelStyleDes,
    } = this.styles;
    const {
      //onClose,
      parent_id } = this.props;
    return (
      <Div
        id={`${parent_id}-parent-div`}
        className="pi-root">
        <Paper
          id={`${parent_id}-parent-paper`}
          className="pi-paper">
          <Div
            id={`${parent_id}-parent-div`}
            style={{ display: "flex", alignItems: "center" }}>
            <H6
              id={`${parent_id}-title-h6`}
              className="pi-title"
              style={{
                display: "flex",
                alignItems: "center",
                margin: 0,
                flex: 1,
              }}
            >
              Practitioner By Role
              {practitionerbyrole?.length > 0 ? (
                <>
                  {" (" + JSON.stringify(practitionerbyrole?.length) + ")"}
                  <Image
                    id={`${parent_id}-image`}
                    style={{ width: 16, height: 16, marginLeft: 5 }}
                    src="images/icons8-ok.svg"
                    alt="tick"
                  />
                </>
              ) : (
                <span
                  id={`${parent_id}-discription-span`}
                  style={labelStyleDes}>
                  {"  ( At leaset add one role )"}
                </span>
              )}
              {/* )} */}
            </H6>
            <Text
              id={`${parent_id}-addCollapseForm-text`}
              inLineStyles={{
                color: "#0071F2",
                fontSize: 12,
                display: "flex",
                alignItems: "center",
              }}
              className="pi-addbtn"
            >
              {addCollapseForm ?
                <Text
                  id={`${parent_id}-Add-New-text`}
                  className="pi-addbtn"
                  style={{ marginRight: 10, color: "gray" }}
                >
                  + Add New
                </Text> :
                <Text
                  id={`${parent_id}-addCollapse-Add-New-text`}
                  className="pi-addbtn"
                  style={{ marginRight: 10 }}
                  onClick={(e) => {
                    this.handleClear();
                    this.addCollapse(e);
                  }}
                >
                  + Add New
                </Text>}
              {practitionerbyrole?.length > 0 && (
                <React.Fragment>
                  {Collapse ? (
                    //  <i onClick={(e) => this.addCollapse(e)} className="fa fa-angle-down ad-arrow" aria-hidden="true" /> : <i className="fa fa-angle-up ad-arrow" aria-hidden="true" onClick={(e) => this.addCollapse(e)} />}
                    <Image
                      id={`${parent_id}-downArrow-image`}
                      src="images/Group 3873.svg"
                      style={{ width: 20, height: 20 }}
                      alt="downArrow"
                      onClick={(e) => this.Collapse(e)}
                    />
                  ) : (
                    <>
                      <Image
                        id={`${parent_id}-upArrow-image`}
                        style={{ width: 20, height: 20 }}
                        src="images/Group -1.svg"
                        alt="upArrow"
                        onClick={(e) => this.Collapse(e)}
                      />
                    </>
                  )}
                </React.Fragment>
              )}
            </Text>
          </Div>
          {Collapse && practitionerbyrole?.length > 0 && (
            <Div
              id={`${parent_id}-submitrole-div`}
            >
              <Submitrole
                parent_id={'Submitrole'}
                onedit={this.onedit}
                ondelete={this.ondelete}
                allMasters={this.props?.allMasters}
                handleClear={this.handleClear}
                index={this.state.editindex}
                editData={this.state.editadd}
                setTo={this.props.setTo}
                saveto={this.props.saveto}
              // onClose={() => this.OpenAddress(null)}
              />
            </Div>
          )}

          {addCollapseForm && (
            <Div
              id={`${parent_id}-addCollapseForm-parent-div`}
            >
              <Paper
                id={`${parent_id}-addCollapseForm-parent-paper`}
              >
                <Row
                  id={`${parent_id}-addCollapseForm-parent-row`}
                >
                  <Col
                    id={`${parent_id}-Select-Role-col`}
                    md={4}
                    lg={4}
                    sm={6}
                    xs={12}
                    className="no-padding-left"
                    inLineStyles={{ padding: 10, color: "#101010" }}
                  >
                    {/* <Select
                      value={this.state.select_role?.label}
                      onChangeValue={(value) =>
                        this.onChangeState("select_role", value)
                      }
                      getOptionLabel="label"
                      options={this.props?.allMasters?.["role"]}
                      label="Select Role"
                      placeholder="select Role"
                      showArrow
                      labelStyle={labelStyle}
                      inLineStyles={
                        this.state?.erorr?.select_role
                          ? errorBorderStyle
                          : borderStyle
                      }
                      hoverColor="#0071F2"
                      isRequired={true}
                    /> */}
                    <Typography
                      id={`${parent_id}-Select-Role-typography`}
                      style={{
                        fontSize: "12px",

                        Fontfamily: "pc_regular",
                        color: "#6F6F6F",
                      }}
                      variant="body1"
                    >
                      Select Role <span
                        id={`${parent_id}-Select-Role-star-span`}
                        style={{ color: "red" }}>*</span>
                    </Typography>
                    <SelectBox
                      parent_id={'select-role'}
                      list={this.props?.allMasters?.["role"] ?? []}
                      placeholder={"Select"}
                      disableClearable
                      value={this.state?.select_role}
                      onchange={(e, value) =>
                        this.onChangeState("select_role", value)
                      }
                      error={this?.state?.erorr && !this?.state?.select_role?._id ? true : false}
                    />
                  </Col>

                  <Col
                    id={`${parent_id}-Speciality-col`}
                    md={4}
                    lg={4}
                    sm={6}
                    xs={12}
                    inLineStyles={{ padding: 10 }}
                  >
                    {/* <Select
                      value={this.state.speciality?.label}
                      helperText={
                        this.state?.error && this.state?.error["speciality"]
                          ? "Is required"
                          : ""
                      }
                      error={
                        this.state?.error && this.state?.error["speciality"]
                          ? true
                          : false
                      }
                      onChangeValue={(value) =>
                        this.onChangeState("speciality", value)
                      }
                      getOptionLabel="label"
                      options={this.props?.allMasters?.["speciality"]}
                      showArrow
                      isRequired={true}
                      label="Speciality"
                      placeholder="select speciality"
                      labelStyle={labelStyle}
                      inLineStyles={
                        this.state?.erorr?.speciality
                          ? errorBorderStyle
                          : borderStyle
                      }
                      hoverColor="#0071F2"
                      isRequired={true}
                    /> */}
                    <Typography
                      id={`${parent_id}-Speciality-title-typography`}
                      style={{
                        fontSize: "12px",

                        Fontfamily: "pc_regular",
                        color: "#6F6F6F",
                      }}
                      variant="body1"
                    >
                      Specialty <span
                        id={`${parent_id}-Speciality-star-span`}
                        style={{ color: "red" }}>*</span>
                    </Typography>
                    <SelectBox
                      parent_id={'speciality'}
                      list={this.props?.allMasters?.["speciality"] ?? []}
                      placeholder={"Select"}
                      disableClearable
                      value={this.state.speciality}
                      onchange={(e, value) =>
                        this.onChangeState("speciality", value)
                      }
                      error={this?.state?.erorr && !this?.state?.speciality?._id ? true : false}
                    />
                  </Col>
                  {!this.checkSpecialty(practitionerbyrole) &&
                    <Col
                      id={`${parent_id}-Primary-Speciality-col`}
                      md={2}
                      lg={2}
                      sm={6}
                      xs={12}
                      className="no-padding-left"
                      inLineStyles={{ padding: 10, color: "#101010" }}
                    >
                      <H6
                        id={`${parent_id}-Primary-Speciality-h6`}
                        className="default-size pc_regular">
                        Primary Specialty
                      </H6>
                      <FormControlLabel
                        id={`${parent_id}-Primary-Speciality-formcontrolLabel`}
                        control={
                          <Checkbox
                            id={`${parent_id}-Primary-Speciality-checkbox`}
                            color={"primary"}
                            checked={this.state?.primary_specialty}
                            onChange={(e) =>
                              this.onChangeState(
                                "primary_specialty",
                                e.target.checked
                              )
                            }
                            name="primary_specialty"
                          />
                        }
                      />
                    </Col>}
                  <Col
                    id={`${parent_id}-status-col`}
                    md={2}
                    lg={2}
                    sm={6}
                    xs={12}
                    className="no-padding-left"
                    inLineStyles={{ padding: 10, color: "#101010" }}
                  >
                    <H6
                      id={`${parent_id}-status-title-h6`}
                      className="default-size pc_regular">Status</H6>
                    <FormControlLabel
                      id={`${parent_id}-status-formcontrolLabel`}
                      control={
                        <this.IOSSwitch
                          id={`${parent_id}-status-IOSSwitch`}
                          checked={this.state?.status}
                          onChange={(e) =>
                            this.onChangeState("status", e.target.checked)
                          }
                        />
                      }
                    />
                  </Col>
                  {/* <Col md={2} lg={2} sm={6} xs={12} className="no-padding-left" inLineStyles={{ padding: 10, color: '#101010' }}>
                        <Select value={practitionerbyrole?.location?.value ?? null} onChangeValue={value => this.setData("location", value)}
                            options={this.FlterOptions('Alternate ID Type')} label="Location" placeholder="Select Location"
                            options={optiondata} showArrow labelStyle={labelStyle} inLineStyles={borderStyle} hoverColor="#0071F2" />
                    </Col> */}
                  <Col
                    id={`${parent_id}-select-entity-col`}
                    md={4}
                    lg={4}
                    sm={6}
                    xs={12}
                    className="no-padding-left"
                    inLineStyles={{ padding: 10, color: "#101010" }}
                  >
                    {/* <Select
                      value={this.state.select_entity?.label}
                      onChangeValue={(value) =>
                        this.onChangeState("select_entity", value)
                      }
                      getOptionLabel="label"
                      options={this.props?.allMasters?.["entity"] ?? []}
                      label="Select Entity"
                      placeholder="select entity"
                      showArrow
                      labelStyle={labelStyle}
                      inLineStyles={borderStyle}
                      hoverColor="#0071F2"
                    /> */}
                    <Typography
                      id={`${parent_id}-select-entity-typography`}
                      style={{
                        fontSize: "12px",

                        Fontfamily: "pc_regular",
                        color: "#6F6F6F",
                      }}
                      variant="body1"
                    >
                      Select Entity<span
                        id={`${parent_id}-Speciality-star-span`}
                        style={{ color: "red" }}>*</span>
                    </Typography>
                    <SelectBox
                      parent_id={'select-entity'}
                      list={this.props?.allMasters?.["entity"] ?? []}
                      placeholder={"Select"}
                      disableClearable
                      value={this?.state?.select_entity ?? {}}
                      onchange={(e, value) =>
                        this.onChangeState("select_entity", value)
                      }
                      error={this?.state?.erorr && !this?.state?.select_entity?._id ? true : false}
                    />
                  </Col>
                  <Col
                    id={`${parent_id}-entity-name-col`}
                    md={5}
                    lg={5}
                    sm={6}
                    xs={12}
                    className="no-padding-left"
                    inLineStyles={{ padding: 10, color: "#101010" }}
                  >
                    {/* <Select
                      value={this.state.entity_name?.label}
                      onChangeValue={(value) =>
                        this.onChangeState("entity_name", value)
                      }
                      options={this.FlterOptions("Alternate ID Type")}
                      label="Entity Name"
                      placeholder="select entity name"
                      getOptionLabel="label"
                      options={this.props?.allMasters?.["entity_name"] ?? []}
                      showArrow
                      labelStyle={labelStyle}
                      inLineStyles={borderStyle}
                      hoverColor="#0071F2"
                    /> */}
                    <Typography
                      id={`${parent_id}-entity-name-typography`}
                      style={{
                        fontSize: "12px",

                        Fontfamily: "pc_regular",
                        color: "#6F6F6F",
                      }}
                      variant="body1"
                    >
                      Entity Name<span
                        id={`${parent_id}-Speciality-star-span`}
                        style={{ color: "red" }}>*</span>
                    </Typography>
                    <SelectBox
                      parent_id={'entity-name'}
                      // list={this.props?.allMasters?.["entity_name"] ?? []}
                      list={this?.props?.entity_name_options ?? []}
                      placeholder={"Select"}
                      disableClearable
                      value={this.state.entity_name}
                      onchange={(e, value) =>
                        this.onChangeState("entity_name", value)
                      }
                      error={this?.state?.erorr && !this?.state?.entity_name?._id ? true : false}
                    />
                    {/* {JSON.stringify(this?.props?.entity_name_options)} */}
                  </Col>
                  <Col
                    id={`${parent_id}-entity-name-col`}
                    md={3}
                    lg={3}
                    sm={6}
                    xs={12}
                    className="no-padding-left"
                    inLineStyles={{ padding: 10, color: "#101010" }}
                  >
                    {/* <Select
                      value={this.state.select_location?.label}
                      onChangeValue={(value) =>
                        this.onChangeState("select_location", value)
                      }
                      label="Select Location"
                      placeholder="select location"
                      getOptionLabel="label"
                      options={this.props?.allMasters?.["location"] ?? []}
                      showArrow
                      labelStyle={labelStyle}
                      inLineStyles={borderStyle}
                      hoverColor="#0071F2"
                    /> */}
                    <Typography
                      id={`${parent_id}-select-location-typography`}
                      style={{
                        fontSize: "12px",

                        Fontfamily: "pc_regular",
                        color: "#6F6F6F",
                      }}
                      variant="body1"
                    >
                      Select Location
                    </Typography>
                    <SelectBox
                      parent_id={'select-location'}
                      list={this.props?.allMasters?.["location"] ?? []}
                      placeholder={"Select"}
                      disableClearable
                      value={this.state.select_location}
                      onchange={(e, value) =>
                        this.onChangeState("select_location", value)
                      }
                    />
                  </Col>

                  <Col
                    id={`${parent_id}-start-date-col`}
                    md={3}
                    lg={3}
                    sm={6}
                    xs={12}
                    inLineStyles={{ padding: 10 }}
                  >
                    {/* <TextInput
                      id={`${parent_id}-start-date-textinput`}
                      value={this.state.start_date}
                      onChange={(e) =>
                        this.onChangeState("start_date", e.target.value)
                      }
                      label="Start Date"
                      max={"9999-12-31"}
                      type="date"
                      labelStyle={labelStyle}
                      style={borderStyle}
                      hoverColor="#0071F2"
                    /> */}
                    <Typography
                      style={{
                        fontFamily: "poppin",
                        color: "#6F6F6F",
                        fontSize: "12px",
                        paddingBottom: "8px",
                      }}
                    >
                      {"Start Date"}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <CustomInputDatePicker

                      disabled={false}
                      placeholderText="dd / mm / yyyy"
                      selectedDate={
                        this.state.start_date ? new Date(this.state.start_date) : null
                        // val?.expiry_date
                        //   ? new Date(utcTOLocal(val?.expiry_date, "MM/DD/YYYY"))
                        //  new Date()
                      }
                      // maxDate={new Date()}
                      // minDate={(this?.props?.edit && this.props.oldData?.data?.identification?.[i]?.expiry_date) ? new Date(this.props.oldData?.data?.identification?.[i]?.expiry_date) : new Date()}
                      handleChange={(value) =>
                        this.onChangeState("start_date", value)
                      }
                      inputField={
                        <TextInput
                          id={`${parent_id}-start-date-textinput`}
                          fullWidth
                          placeholder="dd / mm / yyyy"
                          variant="outlined"
                          required={true}
                          disabled={false}
                          error={this?.state?.erorr && !this?.state?.start_date ? true : false}
                          type={"text"}
                          value={
                            this.state.start_date
                              ? moment(
                                new Date(
                                  // utcTOLocal(val?.expiry_date, "MM/DD/YYYY")
                                  this.state.start_date
                                )
                              ).format("DD/MM/YYYY")
                              : null
                          }
                          inputProps={{
                            style: {
                              padding: "10.5px 14px",
                              fontFamily: "poppin",
                              fontSize: "12px",
                              borderRadius: 8,
                              borderColor: "#e0e0e0",
                              // backgroundColor: data?.disabled
                              //   ? "#E0E0E0"
                              //   : "#fff",
                            },
                          }}
                        />
                      }
                    />
                  </Col>
                  <Col
                    id={`${parent_id}-end-date-col`}
                    md={3}
                    lg={3}
                    sm={6}
                    xs={12}
                    inLineStyles={{ padding: 10 }}
                  >
                    {/* <TextInput
                      id={`${parent_id}-end-date-textinput`}
                      value={this.state.end_date}
                      onChange={(e) =>
                        this.onChangeState("end_date", e.target.value)
                      }
                      label="End Date"
                      min={moment(this.state.start_date).format("YYYY-MM-DD")}
                      max={"9999-12-31"}
                      type="date"
                      labelStyle={labelStyle}
                      style={borderStyle}
                      hoverColor="#0071F2"
                      helperText={(this.state?.start_date?.length > 0 && this.state?.end_date?.length > 0) ? checkMaxDateValidation(this.state?.start_date, this.state?.end_date) ?? " " : " "}
                      error={true}
                    /> */}
                    <Typography
                      style={{
                        fontFamily: "poppin",
                        color: "#6F6F6F",
                        fontSize: "12px",
                        paddingBottom: "8px",
                      }}
                    >
                      {"End Date"}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <CustomInputDatePicker

                      disabled={false}
                      placeholderText="dd / mm / yyyy"
                      selectedDate={
                        this.state.end_date ? new Date(this.state.end_date) : null
                        // val?.expiry_date
                        //   ? new Date(utcTOLocal(val?.expiry_date, "MM/DD/YYYY"))
                        //  new Date()
                      }
                      // maxDate={new Date()}
                      // minDate={(this?.props?.edit && this.props.oldData?.data?.identification?.[i]?.expiry_date) ? new Date(this.props.oldData?.data?.identification?.[i]?.expiry_date) : new Date()}
                      handleChange={(value) =>
                        this.onChangeState("end_date", value)
                      }
                      inputField={
                        <TextInput
                          id={`${parent_id}-start-date-textinput`}
                          fullWidth
                          placeholder="dd / mm / yyyy"
                          variant="outlined"
                          required={true}
                          disabled={false}
                          error={this?.state?.erorr && !this?.state?.end_date ? true : false}
                          type={"text"}
                          value={
                            this.state.end_date
                              ? moment(
                                new Date(
                                  // utcTOLocal(val?.expiry_date, "MM/DD/YYYY")
                                  this.state.end_date
                                )
                              ).format("DD/MM/YYYY")
                              : null
                          }
                          inputProps={{
                            style: {
                              padding: "10.5px 14px",
                              fontFamily: "poppin",
                              fontSize: "12px",
                              borderRadius: 8,
                              borderColor: "#e0e0e0",
                              // backgroundColor: data?.disabled
                              //   ? "#E0E0E0"
                              //   : "#fff",
                            },
                          }}
                        />
                      }
                    />
                  </Col>

                  {this.state?.doc?.[0]?.filename ? (
                    <div
                      id={`${parent_id}-Filenameui-div`}
                      style={{ padding: 10 }}>
                      {/* <Filenameui
                        parent_id={'Filenameui'}
                        fileName={this.state?.doc?.[0]?.filename ?? ""}
                        delete={() => this.onChangeState("doc", null)}
                      /> */}
                    </div>
                  ) : (
                    <Col
                      id={`${parent_id}-Upload-col`}
                      md={3}
                      lg={3}
                      sm={6}
                      xs={12}
                      inLineStyles={{ padding: 10 }}
                    >
                      <div
                        id={`${parent_id}-Upload-div`}
                        style={{ marginTop: "23px", marginLeft: "15px" }}>
                        {/* <UploadFiles /> */}
                        {/* <Upload
                          id={`${parent_id}-Document-Upload`}
                          label="Upload Document"
                          className="pi-upload-btn"
                          handleChange={(e, value) =>
                            this.setDataUpload("doc", value)
                          }
                          labelColor="#0071F2"
                          iconColor="#0071F2"
                          iconSize={14}
                          imgUrl="images/uploadBlue.svg"
                          labelStyle={{
                            fontSize: 14,
                            fontFamily: "pc_medium !important",
                          }}
                        /> */}
                      </div>
                    </Col>
                  )}
                </Row>
              </Paper>

              {/* <PatientCotactPoint
                parent_id={'practitionerbyrole'}
                allMasters={this.props?.allMasters}
                saveto={"patientData"}
                setTo={"setPatientData"}
                setCustom={this.setCustomContact}
                addNewContact={this.addNewContact}
                ondelete={this.deleteContect}
                contactList={this.state?.contact ?? []}
              /> */}

              <div
                id={`${parent_id}-button-div`}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 20,
                }}
              >
                <Button
                  id={`${parent_id}-Cancel-button`}
                  variant="text"
                  className={"topNav-actions cancelBtn"}
                  onClick={() => {
                    this.Clear();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  id={`${parent_id}-Save-button`}
                  onClick={() => this.saveAddress()}
                  inLineStyles={containedButton}
                >
                  Save
                </Button>
              </div>
            </Div>
          )}
        </Paper>
      </Div>
    );
  }
}
const mapStateToProps = (state) => ({
  entity_name_options: state?.practitionerSlice?.get_entity_by_id?.data ?? [],
});
export default connect(mapStateToProps, actions)(withAllContexts(Practitionerbyrole))